import ApiService from '@/shared/services/api.service';

// action types
export const GET_ORGANISATION_LINKS = 'getOrganisationLinks';

// mutation types
export const SET_ORGANISATION_LINKS = 'setOrganisationLinks';

const state = {
    links: [],
};

const getters = {
    links(state) {
        return state.links;
    },
};

const actions = {
    [GET_ORGANISATION_LINKS](context, id) {
        context.commit('shared/activateLoading', 'organisations/getUsefulLinks', { root: true });
        return new Promise((resolve, reject) => {
            ApiService.get(`organisations/${id}/useful-links`)
                .then(({ data }) => {
                    context.commit(SET_ORGANISATION_LINKS, data[0]?.['useful_links']);

                    resolve(data[0]?.['useful_links']);
                })
                .catch(( error ) => {
                    reject(error);
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'organisations/getUsefulLinks', { root: true });
                })
        });
    },
};

const mutations = {
    [SET_ORGANISATION_LINKS](state, links) {
        state.links = links;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
